import React from "react"
// import FadeIn from "react-fade-in"

/** Components */
import Layout from "@components/layout"
import SEO from "../components/seo"
import TitleCard from "@components/TitleCard"
import TextBlock from "@components/TextBlock"
import Block from "@components/Block"
import BulletBlock from "@components/BulletBlock"
import IntroBlock from "@components/IntroBlock"
import ExampleBlock from "@components/ExampleBlock"
import CheckboxBlock from "@components/CheckboxBlock"
import PromoBlock from "@components/PromoBlock"
import Navigation from "@components/Navigation"
import ShareIcons from "@components/ShareIcons"
import SVGImage from "@components/SvgImage"

/** Helpers */
import TextBlockQuery from "../helpers/query-text-block"
import TitleBlockQuery from "../helpers/query-title-block"
import IntroBlockQuery from "../helpers/query-intro-block"
import ExampleBlockQuery from "../helpers/query-example-block"
import BulletBlockQuery from "../helpers/query-bullet-block"
import CheckboxBlockQuery from "../helpers/query-checkbox-block"

const Issue2 = ({ pageContext }) => {
  const editionIdentifier = "allMsIssue2Data"
  const textBlockData = TextBlockQuery(editionIdentifier)
  const introBlockData = IntroBlockQuery(editionIdentifier)
  const titleBlockData = TitleBlockQuery(editionIdentifier)
  const exampleBlockData = ExampleBlockQuery(editionIdentifier)
  const bulletBlockData = BulletBlockQuery(editionIdentifier)
  const checkboxBlockData = CheckboxBlockQuery(editionIdentifier)
  // TODO - Refactor query helper functions

  return (
    <Layout issueNumber="2">
      <Navigation />
      <SEO
        title="Hands Off...But Human"
        description="A brand-new, monthly briefing to help you navigate this wild New Normal."
        issueNumber={2}
      />

      {/* intro section */}
      <div className="columns is-gapless is-multiline reversed-mobile">
        <div className="column is-one-third">
          <IntroBlock {...introBlockData[0]}>
            <ShareIcons shareUrl="https://makeshift.trendwatching.com/issue-2" />
          </IntroBlock>
        </div>
        <div className="column is-one-third">
          <Block background={titleBlockData[0].background_colour}>
            <TitleCard {...titleBlockData[0]} />
          </Block>
        </div>
        <div className="column is-one-third is-hidden-mobile">
          <Block background="FC814A" noPadding svgPosition="top">
            <SVGImage name="ms2-intro-cover-page" directory="svg-blocks" />
          </Block>
        </div>
      </div>
      <div className="columns is-gapless is-multiline">
        <div className="column is-one-third">
          <Block background="FC814A" noPadding svgPosition="center">
            <SVGImage name="ms2-intro-head-left" directory="svg-blocks" />
          </Block>
        </div>
        <div className="column is-one-third is-hidden-mobile">
          <Block background="FC814A" noPadding svgPosition="center">
            <SVGImage name="ms2-intro-head-right" directory="svg-blocks" />
          </Block>
        </div>
        <div className="column is-one-third">
          <Block background={textBlockData[0].background_colour}>
            <TextBlock {...textBlockData[0]} />
          </Block>
        </div>
      </div>

      {/* setting the scene section */}
      <div className="columns is-gapless is-multiline">
        <div className="column is-one-third">
          <Block background={titleBlockData[1].background_colour}>
            <TitleCard {...titleBlockData[1]} />
          </Block>
        </div>
        <div className="column is-one-third">
          <Block background={bulletBlockData[0].background_colour}>
            <BulletBlock {...bulletBlockData[0]} />
          </Block>
        </div>
        <div className="column is-one-third">
          <Block background={bulletBlockData[1].background_colour}>
            <BulletBlock {...bulletBlockData[1]} />
          </Block>
        </div>
      </div>
      <div className="columns is-gapless is-multiline">
        <div className="column is-one-third">
          <Block background={bulletBlockData[2].background_colour}>
            <BulletBlock {...bulletBlockData[2]} />
          </Block>
        </div>
        <div className="column is-one-third">
          <Block background="A8F9FE" noPadding svgPosition="center">
            <SVGImage name="ms2-bullet-divorce" directory="svg-blocks" />
          </Block>
        </div>
        <div className="column is-one-third">
          <Block background={bulletBlockData[3].background_colour}>
            <BulletBlock {...bulletBlockData[3]} />
          </Block>
        </div>
      </div>
      <div className="columns is-gapless is-multiline">
        <div className="column is-one-third">
          <Block background={bulletBlockData[4].background_colour}>
            <BulletBlock {...bulletBlockData[4]} />
          </Block>
        </div>
        <div className="column is-one-third">
          <Block background={bulletBlockData[5].background_colour}>
            <BulletBlock {...bulletBlockData[5]} />
          </Block>
        </div>
        <div className="column is-one-third">
          <Block background="B5FEFE" noPadding svgPosition="center">
            <SVGImage name="ms2-bullet-more-supported" directory="svg-blocks" />
          </Block>
        </div>
      </div>
      <div className="columns is-gapless is-multiline">
        <div className="column is-one-third">
          <Block background={bulletBlockData[6].background_colour}>
            <BulletBlock {...bulletBlockData[6]} />
          </Block>
        </div>
        <div className="column is-one-third">
          <Block background="B5FEFE" noPadding svgPosition="center">
            <SVGImage name="ms2-bullet-final-thoughts" directory="svg-blocks" />
          </Block>
        </div>
        <div className="column is-one-third">
          <Block background={textBlockData[1].background_colour}>
            <TextBlock {...textBlockData[1]} />
          </Block>
        </div>
      </div>

      {/* Brands in Action section */}
      <div className="columns is-gapless is-multiline">
        <div className="column is-one-third">
          <Block background={titleBlockData[2].background_colour}>
            <TitleCard {...titleBlockData[2]} />
          </Block>
        </div>
        <div className="column is-one-third">
          <Block background={textBlockData[2].background_colour}>
            <TextBlock {...textBlockData[2]} />
          </Block>
        </div>
        <div className="column is-one-third">
          <Block background="CCB9F3" noPadding svgPosition="center">
            <SVGImage name="ms2-brands-action" directory="svg-blocks" />
          </Block>
        </div>
      </div>

      {/* Examples section */}
      {exampleBlockData.map((example) => {
        return <ExampleBlock key={example.order} {...example} />
      })}

      {/* Over to you section */}
      <div className="columns is-gapless is-multiline">
        <div className="column is-one-third">
          <Block background={titleBlockData[3].background_colour}>
            <TitleCard {...titleBlockData[3]} />
          </Block>
        </div>
        <div className="column is-one-third">
          <Block background={textBlockData[3].background_colour}>
            <TextBlock {...textBlockData[3]} />
          </Block>
        </div>
        <div className="column is-one-third">
          <Block background="FDAD88" svgPosition="bottom">
            <SVGImage name="ms2-over-you" directory="svg-blocks" />
          </Block>
        </div>
      </div>

      {/* Checklists section */}
      <div className="columns is-gapless is-multiline">
        <div className="column is-one-third">
          <Block background="FC814A" noPadding svgPosition="center">
            <SVGImage name="ms2-checkbox-local-links" directory="svg-blocks" />
          </Block>
        </div>
        <div className="column is-one-third">
          <Block background="FC814A">
            <CheckboxBlock {...checkboxBlockData[0]} order={1} />
          </Block>
        </div>
        <div className="column is-one-third">
          <Block background="FC814A">
            <CheckboxBlock {...checkboxBlockData[1]} order={2} />
          </Block>
        </div>
      </div>
      <div className="columns is-gapless is-multiline">
        <div className="column is-one-third">
          <Block background="FDAD88" noPadding svgPosition="center">
            <SVGImage name="ms2-checkbox-purpose-people" directory="svg-blocks" />
          </Block>
        </div>
        <div className="column is-one-third">
          <Block background="FDAD88">
            <CheckboxBlock {...checkboxBlockData[2]} order={3} />
          </Block>
        </div>
        <div className="column is-one-third">
          <Block background="FDAD88">
            <CheckboxBlock {...checkboxBlockData[3]} order={4} />
          </Block>
        </div>
      </div>
      <div className="columns is-gapless is-multiline">
        <div className="column is-one-third">
          <Block background="FC814A" noPadding svgPosition="center">
            <SVGImage name="ms2-checkbox-gimmick" directory="svg-blocks" />
          </Block>
        </div>
        <div className="column is-one-third">
          <Block background="FC814A">
            <CheckboxBlock {...checkboxBlockData[4]} order={5} />
          </Block>
        </div>
        <div className="column is-one-third is-hidden-mobile">
          <Block background="FC814A"></Block>
        </div>
      </div>
      <div className="columns is-gapless is-multiline">
        <div className="column is-one-third">
          <Block background="FDAD88" noPadding svgPosition="center">
            <SVGImage name="ms2-checkbox-phygital" directory="svg-blocks" />
          </Block>
        </div>
        <div className="column is-one-third">
          <Block background="FDAD88">
            <CheckboxBlock {...checkboxBlockData[5]} order={6} />
          </Block>
        </div>
        <div className="column is-one-third">
          <Block background="FDAD88">
            <CheckboxBlock {...checkboxBlockData[6]} order={7} />
          </Block>
        </div>
      </div>
      <div className="columns is-gapless is-multiline">
        <div className="column is-one-third">
          <Block background="FC814A">
            <CheckboxBlock {...checkboxBlockData[7]} order={8} />
          </Block>
        </div>
        <div className="column is-one-third">
          <Block background="FDAD88">
            <CheckboxBlock {...checkboxBlockData[8]} order={9}>
              <ShareIcons shareUrl="https://makeshift.trendwatching.com/issue-2" />
            </CheckboxBlock>
          </Block>
        </div>
        <div className="column is-one-third">
          <Block background="FDAD88">
            <CheckboxBlock {...checkboxBlockData[9]} order={10} />
          </Block>
        </div>
      </div>

      {/* Outro section */}
      <div className="columns is-gapless is-multiline">
        <div className="column is-one-third">
          <IntroBlock {...introBlockData[1]} outroThanks />
        </div>
        <div className="column is-one-third">
          <PromoBlock
            isPreviousEditions
            bgColor="7D53DE"
            sectionTitle="Here's the opportunities you may have missed."
            buttonUrl="https://trendwatching.com/makeshift"
            issueNumber={2}
          />
        </div>
        <div className="column is-one-third">
          <PromoBlock
            buttonUrl="#"
            bgColor="7D53DE"
            sectionTitle="Get opportunities that matter. Monthly. Always."
            formCopy="Join 100,000+ future-focused professionals in 180 countries already receiving a world of meaningful opportunities. Daily."
            hasSvgTitle
          />
        </div>
      </div>
    </Layout>
  )
}

export default Issue2
